<template>
  <div class="main_wrapper product_list cart_page agent_landing plan_details informations" v-if="this.$router.name=='error'">
    <error-page></error-page>
  </div>
  <div class="main_wrapper product_list cart_page agent_landing plan_details informations" v-else-if="this.$router.name=='decline-reminder-email'">
    <decline-email />
  </div>
  <div v-else-if="this.$route.params.landing === 'access-page'">
    <access-page></access-page>
  </div>
  <div v-else-if="this.$route.params.landing === 'access-pagenext'">
    <access-page-next></access-page-next>
  </div>
  <div v-else-if="this.$route.params.landing === 'landing-page'">
    <landing-page></landing-page>
  </div>
  <div class="main_wrapper product_list cart_page agent_landing plan_details informations" v-else>
<!--    <ben-header-area v-if="include_extra"></ben-header-area>-->
<!--    <header-area v-if="include_old"></header-area>-->
    <router-view></router-view>
    <open-page v-if="landing_type == 'user' && isOpenEnrollment"></open-page>
    <open-page  v-else-if="locationHostName == envHostName && isOpenEnrollment"></open-page>
    <agent-open-page v-else-if="landing_type == 'agent' && isOpenEnrollment && locationHostName !== envHostName"></agent-open-page>
    <group-open-page v-else-if="landing_type == 'group' && isOpenEnrollment && locationHostName !== envHostName"></group-open-page>
<!--    <support-area v-if="include_extra"></support-area>-->
<!--    <ben-footer-area v-if="include_extra"></ben-footer-area>-->
<!--    <footer-area v-if="include_old"></footer-area>-->
  </div>
</template>

<script>
  // import Header from '../components/Header'
  // import BenAdvanceHeader from '../components/BenHeader'
  // import Footer from '../components/Footer'
  import ErrorPage from '../components/404'

  // import BenFooter from '../components/BenFooter'
  // import Support from "../components/Support"
  import OpenPage from "../views/OpenPage"
  import AgentOpenHome from "../views/AgentOpenHome";
  import GroupOpenHome from "../views/GroupOpenHome";
  // import AccessPage from "@/views/AccessPage";
  import LandingPage from "@/components/LandingPage";
  import axios from "axios";
  import Api from "../includes/Api";
  import AccessPageNext from './AccessPageNext.vue';
  import DeclineEmail from '../components/DeclineEmail.vue'
  export default {
    name: "Home",
    components: {
      // 'ben-header-area': BenAdvanceHeader,
      // 'header-area': Header,
      // 'footer-area': Footer,
      // 'ben-footer-area': BenFooter,
      // 'support-area' : Support,
      'open-page' : OpenPage,
      'agent-open-page' : AgentOpenHome,
      'group-open-page' : GroupOpenHome,
      'error-page' : ErrorPage,
      // 'access-page': AccessPage,
      'landing-page': LandingPage,
        'access-page-next':AccessPageNext,
      'decline-email': DeclineEmail
    },
    data: function() {
      return {
        landing_type : '',
        include_extra: true,
        include_old: false,
        portalId: null,
        locationHostName: location.host,
        envHostName: process.env.VUE_APP_SITE_HOST_NAME,
        platformType: ''
      }
    },
    computed: {
      isOpenEnrollment() {
        if(this.$route.name == "main") {
            return true;
        } else {
          return false
        }
      },
      isOpenCommonEnrollment () {
        if(this.$route.name == "main") {
          if (this.$route.params.landing) {
            return false;
          } else {
            return true;
          }
        } else {
          return false
        }
      }
    },
    mounted() {
      console.log('parent called');
      let app = this;
      app.landing_type = window.localStorage.getItem('open_type');
      app.include_extra = app.$route.name !== 'error' && app.$route.name !== 'continue-enrollment';
      app.include_old = app.$route.name === 'continue-enrollment';
      app.fromSite = window.localStorage.getItem('fromSite');
      app.platformType = window.localStorage.getItem('platformType');
      app.fetchGoEnrollInfo();
      app.checkForElevateTowellnessLink();
      app.checkQuoteRedirectionFlow();
    },
    methods: {
      checkForElevateTowellnessLink () {
        let app = this;
        if (app.fromSite === 'elevate' && app.landing_type === 'group') {
            axios.get(process.env.VUE_APP_API_BASE+'/get-elevate-info')
              .then(function (response) {
                if (response.data.status == 'success') {
                  app.portalId = response.data.data.portal_id
                  window.localStorage.setItem('portal_id', app.portalId);
                }
              })
              .catch(function (error) {
                console.log(error);
              });
              this.$router.push('/');
        }
      },
      checkQuoteRedirectionFlow () {
        let app = this
        if (app.platformType == 'rep-quote') {
          if (app.$route.name == 'main' || app.$route.path == '/') {
            app.$router.push('/cart')
          }
        }
      },
      fetchGoEnrollInfo () {
        let app = this
        if ((location.host == process.env.VUE_APP_SITE_HOST_NAME && app.landing_type == 'agent' && app.$route.params.landing) || (location.host == process.env.VUE_APP_SITE_HOST_NAME  && app.landing_type == 'group' && app.$route.params.landing)) {
          console.log('inside fetch go enroll Info ')
          Api.get('/get-domainSite-infromation?site_domain=' + window.btoa(unescape(encodeURIComponent( location.host )))).then((response)=> {
              if(response.data.statusCode == 200) {
                 if (app.landing_type !== 'group') {
                   window.localStorage.setItem('group_id', response.data.data.gid);
                 }
                  window.localStorage.setItem('portal_id', response.data.data.gid);
                  window.localStorage.setItem('enroll_type', 'new-group-member');
                  app.$bus.$emit('getMenuList');
                  app.$bus.$emit('getGroupDetail');
                  app.$bus.$emit('getRecommendedList')
                  // app.$router.push('/');
              }
            }).catch(() => {
              app.$router.push('/404');
            })
        }
      }
    },
    listener: {
      'updateLandingType'() {
        let app = this
        app.landing_type = window.localStorage.getItem('open_type');
      }
    }
  }
</script>
